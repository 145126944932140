<template>
  <v-app>
    <v-app-bar app dark color="primary" flat>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Circuito App</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> {{user.username}} </v-list-item-title>
          <v-list-item-subtitle> {{user.role}} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.path">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import admin_menu from "@/test/menu_admin.json";
import admin_user from "@/test/menu_user.json";

export default {
  name: "Frame",
  data: function () {
    return {
      // General
      drawer: true,
      items: [],
      mini: false,
      // User
      user: {
        username: null,
        role: null,
      },
      user_loading: true,
      user_dialog: false,
      // Data Access
      accesses_loading: true,
      accesses: [],
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      let native_user = this.$store.getters.getUser;

      this.user.username = native_user.username;
      this.user.role = native_user.role;

      this.items = this.user.role === 'admin' ? admin_menu : admin_user;
    },
  },
};
</script>